<template>
  <div class="home">
    <WelcomeComponent/>
  </div>
</template>

<script>
// @ is an alias to /src
import WelcomeComponent from '@/components/WelcomeComponent.vue'

export default {
  name: 'HomeView',
  components: {
    WelcomeComponent
  }
}
</script>
