<template>
  <div class="hello">
    <MDBContainer>
      <div class="d-flex justify-content-center align-items-center">
        <div>
          <div class="text-center mt-2">
            <h3><a href="https://de-de.facebook.com/fire.hair.by.vitalij/"><font-awesome-icon icon="fa-brands fa-square-facebook" /></a> <a href="https://www.instagram.com/firehairbyvitalij"><font-awesome-icon icon="fa-brands fa-instagram" /></a></h3>
          </div>
          <MDBCard class="mt-2">
            <MDBCardBody>
              <MDBCardTitle>Fire-Hair Freilassing</MDBCardTitle>
              <MDBCardText>
                <p>Hier findest du unseren Friseursalon in Freilassing</p>
                <p><font-awesome-icon class="text-primary" icon="fa-solid fa-location-dot" /> <a href="https://goo.gl/maps/hk4AwvSpVX3FqeEt8">Münchener Str. 10A, 83395 Freilassing</a> </p>
                <p><font-awesome-icon class="text-primary" icon="fa-solid fa-square-phone" /> <a href="tel:086545895173">08654 5895173</a> </p>
                <strong>Öffnungszeiten in Freilassing</strong>
                <table>
                  <tbody>
                    <tr>
                      <td>Montag</td>
                      <td>Geschlossen</td>
                    </tr>
                    <tr>
                      <td>Dienstag</td>
                      <td>09:00 – 18:00</td>
                    </tr>
                    <tr>
                      <td>Mittwoch</td>
                      <td>09:00 – 18:00</td>
                    </tr>
                    <tr>
                      <td>Donnerstag</td>
                      <td>09:00 – 20:00</td>
                    </tr>
                    <tr>
                      <td>Freitag</td>
                      <td>09:00 – 20:00</td>
                    </tr>
                    <tr>
                      <td>Samstag</td>
                      <td>09:00 – 13:00</td>
                    </tr>
                    <tr>
                      <td>Sonntag</td>
                      <td>Geschlossen</td>
                    </tr>
                  </tbody>
                </table>
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>

          <MDBCard class="mt-4">
            <MDBCardBody>
              <MDBCardTitle>Fire-Hair München</MDBCardTitle>
              <MDBCardText>
                <p>Hier findest du unseren Friseursalon in München</p>
                <p><font-awesome-icon class="text-primary" icon="fa-solid fa-location-dot" /> <a href="https://goo.gl/maps/yk9wfj82Tg2UbeA57">Helene-Mayer-Ring 6, 80809 München</a></p>
                <p><font-awesome-icon class="text-primary" icon="fa-solid fa-square-phone" /> <a href="tel:08969304320">08969304320</a></p>
                <strong>Öffnungszeiten in München</strong>
                <table>
                  <tbody>
                    <tr>
                      <td>Montag</td>
                      <td>Geschlossen</td>
                    </tr>
                    <tr>
                      <td>Dienstag</td>
                      <td>09:00 – 18:30</td>
                    </tr>
                    <tr>
                      <td>Mittwoch</td>
                      <td>09:00 – 18:30</td>
                    </tr>
                    <tr>
                      <td>Donnerstag</td>
                      <td>09:00 – 20:00</td>
                    </tr>
                    <tr>
                      <td>Freitag</td>
                      <td>09:00 – 20:00</td>
                    </tr>
                    <tr>
                      <td>Samstag</td>
                      <td>09:00 – 13:00</td>
                    </tr>
                    <tr>
                      <td>Sonntag</td>
                      <td>Geschlossen</td>
                    </tr>
                  </tbody>
                </table>
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
          <div class="text-center mt-4">
            <a href="https://de-de.facebook.com/fire.hair.by.vitalij/"><font-awesome-icon icon="fa-brands fa-square-facebook" /></a> <a href="https://www.instagram.com/firehairbyvitalij"><font-awesome-icon icon="fa-brands fa-instagram" /></a>
          </div>
        </div>
      </div>
    </MDBContainer>
  </div>
</template>

<script>
import { MDBContainer, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText } from "mdb-vue-ui-kit";

export default {
  name: "WelcomeComponent",
  components: {
    MDBContainer, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText
  },
  props: {
    msg: String
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
