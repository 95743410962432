<template>
  <HomeView/>
</template>

<script>
import HomeView from './views/HomeView.vue'

export default {
  name: 'App',
  components: {
    HomeView
  }
}
</script>
<style lang="scss">
@import '~@/scss/custom.scss';
@import '~@/../mdb/scss/index.free.scss';

#app {
  font-family: Helvetica, Arial, sans-serif;
    background-color: #000000;
}
</style>
