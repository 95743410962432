import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faLocationDot, faSquarePhone } from '@fortawesome/free-solid-svg-icons'
import { faSquareFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'

/* add icons to the library */
library.add(faLocationDot)
library.add(faSquarePhone)
library.add(faSquareFacebook)
library.add(faInstagram)


createApp(App).use(store).use(router).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
